<app-create-account-layout>
  <div class="company__wrapper flex flex-col gap-[1.88rem] xl:gap-5">
    <div>
      <app-onboarding-heading
        pageTitle="Confirm Director’s Details"
        skipLink="/create-account/corporate/add-signatory"
      ></app-onboarding-heading>
    </div>

    <div class="page-grid__wrapper xl:grid xl:grid-cols-4">
      <div
        class="page-content__wrapper flex flex-col gap-[1.88rem] xl:gap-5 xl:col-start-2 xl:col-end-4"
      >
        <div class="steps">
          <app-progress-steps
            [totalSteps]="7"
            [currentStep]="4"
            iconImageSrc="/assets/images/svgs/user-rounded--outline.svg"
          >
          </app-progress-steps>
        </div>

        <div class="flex flex-col gap-5">
          <p class="text-sm text-mango-gray74 cta__text">
            Please confirm the following Director information and proceed to
            signatory
          </p>

          @for (director of directorsList; track director.valueOf) {
            <div
              class="director__wrapper rounded-[0.625rem] py-[0.62rem] px-[0.88rem] bg-mango-orangeFF"
            >
              <div
                class="inline-flex justify-between items-center w-full xl:px-[0.88rem]"
              >
                <span class="text-mango-black17 text-base font-bold"
                  >{{ director.first_name }} {{ director.last_name }}</span
                >
                <img
                  src="/assets/images/svgs/pen-in-square--yellow.svg"
                  alt=""
                  class="w-6 h-5 shrink-0 cursor-pointer"
                />
              </div>
            </div>
          }
          <button
            mango-button
            [routerLink]="CORPORATE_ACCOUNT_ROUTES.addSignatory.currentUrl"
          >
            Proceed
          </button>

          <a
            [routerLink]="CORPORATE_ACCOUNT_ROUTES.directorDetails.currentUrl"
            class="text-center text-sm font-extrabold text-mango leading-[1.3125rem] cursor-pointer hover:underline"
            >Add another Director</a
          >
        </div>
      </div>
    </div>
  </div>
</app-create-account-layout>
