import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateAccountLayoutComponent } from '@app/create-account/create-account.layout.component';

import { OnboardingHeadingComponent } from '@app/shared/ui/onboarding-heading/onboarding-heading.component';
import { ProgressStepsComponent } from '@app/shared/ui/progress-steps/progress-steps.component';
import { RouterOutlet, RouterLink, RouterLinkActive } from '@angular/router';
import { ButtonDirective } from '@app/shared/directives/button.directive';
import { corporateRoutesConfig } from '@create-account/corporate/corporate-routes.config';
import { NotificationFacade } from '@app/core/facades/notification.facade';
import { Store } from '@ngxs/store';
import { AuthSelectors } from '@app/auth/store/auth.selectors';
import { Observable, Subscription } from 'rxjs';
import { GetKycDirectorData, KycService } from '@app/core/services/client/kyc.service';
import { LoadingService } from '@app/core/services/loading.service';
@Component({
  selector: 'app-create-account-confirm-director',
  standalone: true,
  imports: [
    CommonModule,
    CreateAccountLayoutComponent,
    OnboardingHeadingComponent,
    ProgressStepsComponent,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    ButtonDirective,
  ],
  templateUrl: './create-account-confirm-director.component.html',
  styles: `
    .director__wrapper {
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }

  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateAccountConfirmDirectorComponent {
  CORPORATE_ACCOUNT_ROUTES = corporateRoutesConfig;

  toast = inject(NotificationFacade);
  store = inject(Store);
  kycService = inject(KycService);
  loadingService = inject(LoadingService);
  change = inject(ChangeDetectorRef);

  accountId!: string;
  isLoading$!: Observable<boolean>;
  directorsList: GetKycDirectorData[] = [];
  subs: Subscription[] = [];


  constructor() {
    this.isLoading$ = this.loadingService.getLoadingObservable(
      'confirm-director'
    );
  }

  ngOnInit() {
    this.accountId = this.store.selectSnapshot(AuthSelectors.getaccountID)!;

    if (this.accountId) {
      this.getAccountDirectors();
    }
    this.change.detectChanges();
  }

  getAccountDirectors(): void {
    this.loadingService.setLoading('confirm-director', true);

    const formData = { account_id: this.accountId };
    const sub = this.kycService
      .getKycDirector(formData)
      .subscribe({
        next: (response) => {
          this.loadingService.setLoading('confirm-director', false);

          this.directorsList = response.data;
          this.change.detectChanges();
        },
        error: (response) => {
          this.loadingService.setLoading('confirm-director', false);

          this.toast.error('Failed to get account directors');
          this.change.detectChanges();
        },
      });

    this.subs.push(sub);
  }
  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
